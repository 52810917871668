// Stavy výroby
import { blue, cyan, green, purple, red, yellow } from '@asaprint/asap/theme.js';
import { progressBarStripes } from '@asaprint/common/helpers/animations.js';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx.js';

export enum OrderReceivedStatus {
  NEW = 'new',
  OPEN = 'open',
  PROCESSING = 'processing',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  DONE = 'done',
  CLOSED = 'closed', // unused
}

export const ORDER_RECEIVED_STATUS_NAMES = {
  [OrderReceivedStatus.NEW]: 'Nová',
  [OrderReceivedStatus.OPEN]: 'Otvorená',
  [OrderReceivedStatus.PROCESSING]: 'Prebieha',
  [OrderReceivedStatus.PAUSED]: 'Pozastavená',
  [OrderReceivedStatus.CANCELED]: 'Stornovaná',
  [OrderReceivedStatus.DONE]: 'Dokončená',
  [OrderReceivedStatus.CLOSED]: 'Uzavrená',
};

export enum OrderReceivedPhase {
  CONFIGURATION = 'configuration',
  PRODUCTION = 'production',
  CHECK_1 = 'check-1',
  CHECK_2 = 'check-2',
  EXPEDITION = 'expedition',
  ASSEMBLY = 'assembly',
  WAITING_FOR_INVOICE = 'waiting-for-invoice',
  INVOICE = 'invoice',
  PACKAGING = 'packaging',
  PLANNING = 'planning',
  DONE = 'done',
  CANCELED = 'canceled',
  DELIVERY = 'delivery',
}

export const ORDER_RECEIVED_PHASE_NAMES = {
  [OrderReceivedPhase.CONFIGURATION]: 'Konfigurácia',
  [OrderReceivedPhase.PRODUCTION]: 'Výroba',
  [OrderReceivedPhase.CHECK_1]: 'Kontrola 1',
  [OrderReceivedPhase.CHECK_2]: 'Kontrola 2',
  [OrderReceivedPhase.EXPEDITION]: 'Expedícia',
  [OrderReceivedPhase.ASSEMBLY]: 'Montáž',
  [OrderReceivedPhase.WAITING_FOR_INVOICE]: 'Ochranná doba pred fakturáciou',
  [OrderReceivedPhase.INVOICE]: 'Fakturácia',
  [OrderReceivedPhase.PACKAGING]: 'Balenie objednávky',
  [OrderReceivedPhase.PLANNING]: 'Kontrola pred montážou',
  [OrderReceivedPhase.DONE]: 'Ukončená',
  [OrderReceivedPhase.CANCELED]: 'Stornovaná',
  [OrderReceivedPhase.DELIVERY]: 'Expedícia (Doprava ASAPRINT)',
};

export const phaseSx: Record<OrderReceivedPhase, SystemStyleObject> = {
  [OrderReceivedPhase.CONFIGURATION]: {
    backgroundColor: yellow,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.PRODUCTION]: {
    backgroundColor: red,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.CHECK_1]: {
    backgroundColor: cyan,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.CHECK_2]: {
    backgroundColor: cyan,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.EXPEDITION]: {
    backgroundColor: blue,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.CANCELED]: {
    backgroundColor: blue,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.ASSEMBLY]: {
    backgroundColor: '#6e6e6e',
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.WAITING_FOR_INVOICE]: {
    backgroundColor: purple,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.INVOICE]: {
    backgroundColor: green,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.PACKAGING]: {
    backgroundColor: green,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.DELIVERY]: {
    backgroundColor: blue,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.PLANNING]: {
    backgroundColor: cyan,
    color: (theme: Theme) => theme.palette.common.white,
  },

  [OrderReceivedPhase.DONE]: {
    backgroundColor: '#000',
    color: (theme: Theme) => theme.palette.common.white,
  },
};

export const statusSx: Record<OrderReceivedStatus | 'active', SystemStyleObject> = {
  [OrderReceivedStatus.NEW]: {
    backgroundColor: yellow,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [OrderReceivedStatus.OPEN]: {
    backgroundColor: red,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [OrderReceivedStatus.PROCESSING]: {
    backgroundColor: cyan,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [OrderReceivedStatus.PAUSED]: {
    backgroundColor: purple,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [OrderReceivedStatus.CANCELED]: {
    backgroundColor: blue,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [OrderReceivedStatus.DONE]: {
    backgroundColor: '#000',
    color: (theme: Theme) => theme.palette.common.white,
  },
  [OrderReceivedStatus.CLOSED]: {
    backgroundColor: '#000',
    color: (theme: Theme) => theme.palette.common.white,
  },
  active: {
    backgroundImage:
      'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
    backgroundSize: '1rem 1rem',
    animation: `${progressBarStripes} 1s linear infinite`,
  },
};
export const statusOptions = [
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.NEW],
    value: OrderReceivedStatus.NEW,
  },
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.OPEN],
    value: OrderReceivedStatus.OPEN,
  },
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.PROCESSING],
    value: OrderReceivedStatus.PROCESSING,
  },
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.PAUSED],
    value: OrderReceivedStatus.PAUSED,
  },
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.CANCELED],
    value: OrderReceivedStatus.CANCELED,
  },
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.DONE],
    value: OrderReceivedStatus.DONE,
  },
  {
    label: ORDER_RECEIVED_STATUS_NAMES[OrderReceivedStatus.CLOSED],
    value: OrderReceivedStatus.CLOSED,
  },
];
export const phaseOptions = [
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.CONFIGURATION],
    value: OrderReceivedPhase.CONFIGURATION,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.PRODUCTION],
    value: OrderReceivedPhase.PRODUCTION,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.CHECK_1],
    value: OrderReceivedPhase.CHECK_1,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.CHECK_2],
    value: OrderReceivedPhase.CHECK_2,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.EXPEDITION],
    value: OrderReceivedPhase.EXPEDITION,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.ASSEMBLY],
    value: OrderReceivedPhase.ASSEMBLY,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.WAITING_FOR_INVOICE],
    value: OrderReceivedPhase.WAITING_FOR_INVOICE,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.INVOICE],
    value: OrderReceivedPhase.INVOICE,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.PACKAGING],
    value: OrderReceivedPhase.PACKAGING,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.DELIVERY],
    value: OrderReceivedPhase.DELIVERY,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.PLANNING],
    value: OrderReceivedPhase.PLANNING,
  },
  {
    label: ORDER_RECEIVED_PHASE_NAMES[OrderReceivedPhase.DONE],
    value: OrderReceivedPhase.DONE,
  },
];
